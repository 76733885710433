<template>
    <div :class="['question', setBackground]" @click="$emit('selected', questionId)">
        <span>{{ number }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            status: {type: Object, required: true},
            number: {type: Number, required: true},
            questionId: {type: Number}
        },
        computed: {
            setBackground() {
                if (this.status && this.status.viewed) {
                    if (this.status.actual) return 'actual-background';
                    else if (this.status.toReview && this.status.answered) return 'to-review-background';
                    else if (this.status.answered) return 'answered-background';
                    else return 'not-answered-background';
                }
                return 'default-background';
            }
        },
    }
</script>

<style lang="sass" scoped>
    @import '../Sass/variables_exams'

    .question
        width: 1.5rem
        height: 1.5rem
        border-radius: 1.5rem
        display: flex
        align-items: center
        justify-content: center
        position: relative
        float: left
        margin: .3rem

        span
            font-size: 12px
            font-weight: bold
            color: white
</style>
